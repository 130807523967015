.float-btn {
  position: fixed !important;
  right: 24px;
  bottom: 24px;
  // background: #ff9800 !important;
  background: linear-gradient(to bottom, #ff9800, #ff9800);
  .MuiFab-label {
    color: #fff;
  }
  &.with-footer {
    bottom: 68px;
  }
  &.with-float-amt {
    bottom: 100px;
  }
}

// .MuiFab-root.Mui-disabled {
//   color: rgba(0, 0, 0, 0.26) !important;
//   box-shadow: none;
//   background-color: rgba(0, 0, 0, 0.12) !important;
// }

.float-img-upload {
  label {
    width: 45px;
    height: 45px;
    position: absolute;
    border-radius: 100px;
    opacity: 0;
    cursor: pointer;
  }
  &.MuiButtonBase-root {
    position: absolute;
    left: 65px;
    right: 0;
    margin: 75px auto 0px;
  }
}

button.MuiButtonBase-root.MuiButton-root.btn-subheader-action {
  background-color: #ff9800;
  color: white;
  font-size: 12px;
  font-weight: 600;
  float: right;
}

button.MuiButton-root {
  text-transform: initial;
}

.mega-option {
  min-width: 80px;
  .MuiSvgIcon-root {
    display: block;
    width: 16px;
    margin: 0 auto;
  }
  .MuiIconButton-label {
    justify-content: start;
  }
  .icon {
    border: 1px solid #ff9800;
    width: 100%;
    border-radius: 3px;
    padding: 6px 8px;
  }
  .icon-selected {
    width: 100%;
    border: 1px solid #ff9800;
    background-color: #ff9800;
    color: #fff;
    border-radius: 3px;
    padding: 6px 8px;
  }
}
// HRX-3985 - for welcome page
.float-btn-expand {
  left: calc(0px + 160px);
  right: 0;
  margin: auto !important;
}
@media (max-width: 600px) {
  .float-btn-expand {
    left: 0;
    right: 0;
    margin: auto !important;
  }
}
